
import * as ipxRuntime$MF0qmcAaJX from '/builds/neeo33/portfolio/src/frontend/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$JT2oDd3MhC from '/builds/neeo33/portfolio/src/frontend/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 575,
    "md": 767,
    "lg": 991,
    "xl": 1199,
    "xxl": 1499,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "jekabovics.id.lv"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$MF0qmcAaJX, defaults: {"baseURL":"/_ipx_martins"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$JT2oDd3MhC, defaults: {} }
}
        